<template>
  <div>
    <aside class="right-bar"
      :class="{ 'open-marketplace': isDrawerOpen, 'open-notification': isNotificationDrawer, 'open-loghistory': isLogHistoryDrawer, 'open-mobiusai': isMobiusAiDrawer }">
      <section class="plus-icon">
        <img src="../assets/icons/puls_icon.svg" />
      </section>
      <section class="middle-icons">
        <div class="middle-right-icon">
          <img src="../assets/icons/mobius_ai.svg" alt="Mobius AI" @click="openMobiusAIDrawer" />
          <img src="../assets/icons/contructs.svg" />
          <img src="../assets/icons/market_place_management.svg" alt="Marketplace" @click="openMarketplaceDrawer" />
          <img src="../assets/icons/log_history.svg" alt="Log History" @click="openLogHistoryDrawer" />
          <img src="../assets/icons/collaborate.svg" />
          <img src="../assets/icons/notifications.svg" alt="Notification" @click="openNotificationDrawer" />
        </div>
        <div class="delete-icon">
          <img src="../assets/icons/Delete.svg" />
        </div>
      </section>
    </aside>
    <section class="drawer"
      :class="{ 'open-marketplace': isDrawerOpen, 'open-notification': isNotificationDrawer, 'open-loghistory': isLogHistoryDrawer, 'open-mobiusai': isMobiusAiDrawer }">
    </section>
    <ProductMarketplace v-if="isDrawerOpen" />
    <Loghistory v-if="isLogHistoryDrawer" />
    <Notifications v-if="isNotificationDrawer" />
    <Mobiusai v-if="isMobiusAiDrawer" />

  </div>
</template>

<script>
import ProductMarketplace from "../components/Productmanagement.vue"
import Loghistory from "../components/Loghistory.vue";
import Notifications from "../components/Notifications.vue";
import Mobiusai from "../components/Mobiusai.vue";

export default {
  data() {
    return {
      isDrawerOpen: false,
      isNotificationDrawer: false,
      isLogHistoryDrawer: false,
      isMobiusAiDrawer: false
    };
  },
  components: {
    ProductMarketplace,
    Loghistory,
    Notifications,
    Mobiusai
  },
  methods: {
    openMarketplaceDrawer() {
      console.log("Toggling Marketplace Drawer");
      this.isDrawerOpen = !this.isDrawerOpen;
      if (this.isDrawerOpen) {
        this.isNotificationDrawer = false;
        this.isLogHistoryDrawer = false;
        this.isMobiusAiDrawer = false;
      }
    },
    openNotificationDrawer() {
      console.log("Toggling Notification Drawer");
      this.isNotificationDrawer = !this.isNotificationDrawer;
      if (this.isNotificationDrawer) {
        this.isLogHistoryDrawer = false;
        this.isDrawerOpen = false;
        this.isMobiusAiDrawer = false;
      }
    },
    openLogHistoryDrawer() {
      console.log("Toggling Log Histroy Drawer");
      this.isLogHistoryDrawer = !this.isLogHistoryDrawer;
      if (this.isLogHistoryDrawer) {
        this.isNotificationDrawer = false;
        this.isDrawerOpen = false; 
        this.isMobiusAiDrawer = false;
      }
    },
    openMobiusAIDrawer() {
      console.log("Toggling Mobius AI Drawer");
      this.isMobiusAiDrawer = !this.isMobiusAiDrawer;
      if (this.isMobiusAiDrawer) {
        this.isNotificationDrawer = false;
        this.isLogHistoryDrawer = false;
        this.isDrawerOpen = false;
      }
    }
  }
}
</script>

<style scoped>
.right-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 2px solid #dde5e9;
  border-right: 2px solid #dde5e9;
  background: #fff;
  height: 100%;
  width: 50px;
  position: fixed;
  top: 0;
  right: 0px;
  z-index: 1;
  transition: right 0.3s ease;
}

.open-right-bar {
  width: 300px;
}

.middle-icons {
  width: 100%;
  margin-top: auto;
}

.plus-icon {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 12px;
}

.middle-right-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.middle-right-icon img {
  margin: 10px;
}

.delete-icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.drawer {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.open-marketplace {
  right: calc(50px + 600px);
}

.open-notification {
  right: calc(50px + 262px);
}

.open-loghistory {
  right: calc(50px + 262px);
}

.open-mobiusai {
  right: calc(50px + 262px);
}
</style>