
<template>
    <section class="right-notification-drawer">
        <div class="form-header">
            <div class="notification-header">
                <span class="notification">Notifications</span>
            </div>
            <div>
                <img class="filter-icon" src="../assets/icons/filter_icon.svg" />
                <img class="move-right-icon" src="../assets/icons/move_right_icon.svg" />
            </div>
        </div>
        <!-- Two tabs  -->
        <div class="construct-head">
            <div class="info-all-con" :class="{ active: activeTab === 'all' }" @click="setActiveTab('all')">
                <div class="ping">
                    <span>All</span>
                    <div class="notification-bar">
                        <img src="../assets/icons/notification-icon.svg" />
                    </div>
                </div>
            </div>
            <div class="info-all-con" :class="{ active: activeTab === 'unread' }" @click="setActiveTab('unread')">
                <div class="ping">
                    <span>Unread</span>
                    <div class="notification-bar">
                        <img src="../assets/icons/notification-icon.svg" />
                    </div>
                </div>
            </div>
        </div>
        <main class="main-notification">
            <div v-for="(item, index) in filteredNotificationData" :key="index" class="scroll-container display-flex">
                <form class="request-container">
                    <div class="padding-construct" :class="setUnread(item.status, activeTab)"
                        v-if="item.type === 'request'">
                        <div class="request-mail">
                            <img class='request-img' src="../assets/icons/request_image.svg" alt="Requester Image">
                            <p class="email">{{ item.requesterEmail }}</p>
                        </div>
                        <div class="employee-request-details">
                            <div class="employees">
                                <div style="display: flex;  gap: 5px;">
                                    <img class='employee-img' src="../assets/icons/employee_img.svg" alt="Employee Image"/>
                                    <div class="req-employee">
                                        <p class="req-name">{{ item.requestedName }}</p>
                                        <p class="req-type">{{ item.requestedType }}</p>
                                    </div>
                                </div>
                                <p class="req-description">{{ item.requestedDescription }}</p>
                            </div>
                            <div style="margin-top: 6px;">
                                <span class="data-view">Data</span>
                                <div class="permissions-btn">
                                    <div class="buttons">
                                        <div class="switch-toggle" @click="dataWriteToggle">
                                            <input type="checkbox" v-model="dataWrite" class="input-checkbox">
                                            <span class="slider"></span>
                                        </div>
                                        <span>Write</span>
                                    </div>
                                    <div class="buttons">
                                        <div class="switch-toggle" @click="dataReadToggle">
                                            <input type="checkbox" v-model="dataRead" class="input-checkbox">
                                            <span class="slider"></span>
                                        </div>
                                        <span>Read</span>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: 6px;">
                                <span class="data-view">Metadata</span>
                                <div class="permissions-btn">
                                    <div class="buttons-container">
                                        <div class="buttons">
                                            <div class="switch-toggle" @click="metadataReadToggle">
                                                <input type="checkbox" v-model="metadataRead" class="input-checkbox">
                                                <span class="slider"></span>
                                            </div>
                                            <span>Read</span>
                                        </div>
                                        <div class="buttons">
                                            <div class="switch-toggle" @click="metadataWriteToggle">
                                                <input type="checkbox" v-model="metadataWrite" class="input-checkbox">
                                                <span class="slider"></span>
                                            </div>
                                            <span>Write</span>
                                        </div>
                                        <div class="buttons">
                                            <div class="switch-toggle" @click="metadataExecuteToggle">
                                                <input type="checkbox" v-model="metadataExecute" class="input-checkbox">
                                                <span class="slider"></span>
                                            </div>
                                            <span>Execute</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="context-box">
                            <span class="context">Context</span>
                            <input id="context" type="number" class="context-input" placeholder="Select" required>
                        </div>
                        <div class="button-boxes">
                            <button class="approve-btn">
                                <img src='../assets/icons/right_circle.svg' alt="right cross" />Approve
                            </button>
                            <button class="decline-btn">
                                <img src="../assets/icons/white_cross_mark.svg" alt="cross" />Decline
                            </button>
                        </div>
                    </div>
                </form>
                <div class="padding-construct" :class="setUnread(item.status, activeTab)" v-if="item.type === 'grant'">
                    <div class="grante-wrap">
                        <div class="grante-mail">
                            <img class='grante-img' src="../assets/icons/grante_image.svg" alt="Grante Image">
                            <p class="email">{{ item.grantedEmail }}</p>
                        </div>
                        <div class="employee-grante-details">
                            <div class="employees">
                                <div style="display: flex;  gap: 5px;">
                                    <img class='employee-img' src="../assets/icons/employee_img.svg" alt="Employee Image"/>
                                    <div class="req-employee">
                                        <p class="req-name">{{ item.grantedName }}</p>
                                        <p class="req-type">{{ item.grantedType }}</p>
                                    </div>
                                </div>
                                <p class="req-description">{{ item.grantedDescription }}</p>
                            </div>
                            <p class="grante-data-mail"> <b>{{ item.metadataRead }}</b> and
                                <b>{{ item.metadataWrite }}</b> Granted by
                                <b>{{ item.granteDataMail }}</b>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="padding-construct" :class="setUnread(item.status, activeTab)"
                    v-if="item.type === 'completed'">
                    <div style="display: flex;  gap: 5px;padding: 10px;">
                        <img class='employee-img' src="../assets/icons/employee_img.svg" alt="Employee Image"/>
                        <p class="data-ingestion-description"><b>{{ item.cronPlants }}</b>
                            successfully
                            completed data ingestion <b>{{ item.dateTime }}</b></p>
                    </div>
                </div>
                <div class="padding-construct" :class="setUnread(item.status, activeTab)" v-if="item.type === 'added'">
                    <div style="display: flex;  gap: 5px;padding: 10px;">
                        <img class='employee-img' src="../assets/icons/employee_img.svg" alt="Employee Image"/>
                        <p class="data-ingestion-description"><b>{{ item.productName }}</b>
                            has
                            been
                            added
                            to Nightingable Dataserve <b>{{ item.dateTime }}</b></p>
                    </div>
                </div>
                <div class="padding-construct" :class="setUnread(item.status, activeTab)"
                    v-if="item.type === 'started'">
                    <div style="display: flex;  gap: 5px;padding: 10px;">
                        <img class='employee-img' src="../assets/icons/employee_img.svg" alt="Employee Image"/>
                        <p class="data-ingestion-description"><b>{{ item.cronPlants }}</b> has
                            started
                            data ingestion <b>{{ item.dateTime }}</b></p>
                    </div>
                </div>
            </div>
        </main>
    </section>
</template>
<script>
export default {
    data() {
        return {
            clicked: false,
            activeTab: 'all',
            dataRead: false,
            dataWrite: false,
            metadataRead: false,
            metadataWrite: false,
            metadataExecute: false,
            unread: false,
            notificationData: [
                {
                    status: 'unread',
                    type: 'request',
                    requesterEmail: 'tuls@solution.com has requested access to',
                    requestedName: 'Employee',
                    requestedType: 'Entity Model',
                    requestedDescription: 'Employment details of all the employees in the organisation'
                },
                {
                    status: 'unread',
                    type: 'grant',
                    grantedEmail: 'madhav@solution.com has granted access to',
                    grantedName: 'Employee',
                    grantedType: 'Entity Model',
                    grantedDescription: 'Access will be revoked 1 month after the first time the artifact is accessed',
                    metadataRead: 'Metadata read',
                    metadataWrite: 'Metadata write',
                    granteDataMail: 'mo@solution.com',
                },
                {
                    status: 'read',
                    type: 'completed',
                    cronPlants: 'Annual Corn',
                    dateTime: 'today at 12:38pm'
                },
                {
                    status: 'read',
                    type: 'added',
                    productName: 'Hi-Po Chorot',
                    dateTime: 'today at 12:38pm'
                },
                {
                    status: 'unread',
                    type: 'started',
                    cronPlants: 'Annual Corn',
                    dateTime: 'today at 12:38pm'
                }

            ]
        }
    },
    computed: {
        filteredNotificationData() {
            if (this.activeTab == 'unread') {
                return this.notificationData.filter(item => item.status === 'unread');
            } else {
                return this.notificationData;
            }
        }
    },
    methods: {
        dataWriteToggle() {
            this.dataWrite = !this.dataWrite;
        },
        dataReadToggle() {
            this.dataRead = !this.dataRead;
        },
        metadataReadToggle() {
            this.metadataRead = !this.metadataRead;
        },
        metadataWriteToggle() {
            this.metadataWrite = !this.metadataWrite;
        },
        metadataExecuteToggle() {
            this.metadataExecute = !this.metadataExecute;
        },
        setActiveTab(tab) {
            this.activeTab = tab;
        },
        setUnread(status, activeTab) {
            if (activeTab == 'unread' && status == 'unread') {
                this.unread = true;
                return 'bg-unread';
            } else if (activeTab != 'unread' && status == 'unread') {
                return 'bg-unread';
            } else {
                return 'bg-white';
            }
        }
    }
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 5px;
}

.right-notification-drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 312px;
    height: 100%;
    border: 1px solid #eaeaea;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    transition: transform 0.3s ease;
}

.right-notification-drawer button,
.right-notification-drawer input,
.right-notification-drawer label {
    cursor: pointer;
}

.right-notification-drawer.notification-drawer-closed {
    width: 0;
}

.form-header {
    flex: 1;
    border: 1px solid #EAEAEA;
    display: flex;
    justify-content: space-between;
    /* max-width: calc(100% - 10vh); */
}

.notification-header {
    padding: 5px;
}

.notification {
    color: #2C2C2C;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.filter-icon {
    padding: 5px;
}

.move-right-icon {
    padding: 5px;
}

.construct-head {
    display: flex;
    height: 32px;
    color: var(--bg-text, #2C2C2C);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    width: 100%;
}

.info-all-con {
    width: 50%;
    display: flex;
    color: #CFCFCF;
    justify-content: center;
    align-items: center;
    border-bottom: none;
    cursor: pointer;
}

.info-all-con.active {
    border-bottom: 1px solid var(--bg-text, #2C2C2C);
    color: #2c2c2c;
}

.ping {
    position: relative;
    display: flex;
    padding: 8px 15px;
}

.notification-bar {
    position: absolute;
    right: 0;
    top: 0;
}

.main-notification {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 70px);
}

.request-mail {
    width: 280px;
    height: 36px;
    margin: 0px 5px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.email {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    flex: 1;
    color: #2F3238;
    font-family: 'Plus Jakarta Sans', sans-serif;
    overflow: hidden;
}

.request-img {
    height: 24px;
    width: 24px;
}

.employee-request-details {
    width: 248px;
    height: 186px;
    margin-top: 10px;
    margin-left: 40px;
}

.employee-img {
    width: 24px;
    height: 24px;
    margin-top: 5px;
}

.employees {
    width: 248px;
    height: 66px;
}

.req-employee {
    width: 68px;
    height: 34px;
}

.req-name {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 18px;
    color: #2F3238;
}

.req-type {
    font-weight: 400;
    font-size: 10px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 12px;
    color: #424750;
}

.req-description {
    font-weight: 400;
    font-size: 10px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 12px;
    color: #424750;
    width: 248px;
    height: 24px;
    margin-top: 3px
}

.data-view {
    font-weight: 600;
    font-size: 10px;
    margin-top: 5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 12px;
    color: #717C8D;

}

.permissions-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
}

.buttons {
    display: flex;
    gap: 10px;
    height: 16px;
    width: calc(50% - 5px);
    font-weight: 400;
    font-size: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 16px;
    align-items: center;
}

.buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    height: 16px;
}

.switch-toggle {
    /* width: 54px; */
    height: 16px;
    transform: rotate(90deg);
    position: relative;
    display: inline-block;
    width: 1.5em;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 16px;
    overflow: hidden;
    border-radius: 4px;
}

.input-checkbox {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 4px;
    transition: 0.4s;
}

.input-checkbox:checked+.slider {
    background-color: #00BA3E;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12.5px;
    width: 5px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 10%;
    transition: 0.4s;
}

.input-checkbox:checked+.slider:before {
    transform: translateX(9px);
}

.context-box {
    width: 280px;
    height: 54px;
    margin: 10px;
}

.context {
    width: 280px;
    height: 16px;
    font-weight: 600;
    font-size: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 16px;
    color: #2F3238;
    opacity: 0.7;
}

.context-input {
    width: 280px;
    height: 32px;
    border: none;
    border-right: 2px solid #0387d3;
    margin-top: 5px;
    padding-left: 15px;
    color: #2f3238;
    background-color: #f9f9f9;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border-radius: 3px;
    clip-path: polygon(0 95%, 3% 0, 15200% 100%, 0 100%);
}

.button-boxes {
    width: 246px;
    height: 32px;
    margin: 20px 30px;
    display: flex;
    justify-content: space-between;
}

.approve-btn {
    width: 114px;
    height: 32px;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 16px;
    background-color: #22C55E;
}

.decline-btn {
    width: 108px;
    height: 32px;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 16px;
    background-color: #F84F49;
}

.approve-btn,
.decline-btn {
    border: none;
    border-radius: 0;
    color: #FFFFFF;
}

.button-boxes .approve-btn img {
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 5px;
}

.button-boxes .decline-btn img {
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 5px;
}

.green-text {
    color: #00BA3E !important;
}

.padding-construct {
    border-bottom: 1px solid var(--Grey-4, #CFCFCF);
    padding: 8px 0 16px;
}

.employee-grante-details {
    width: 248px;
    height: 125px;
    margin-top: 10px;
    margin-left: 30px;
}

.grante-mail {
    width: 280px;
    height: 36px;
    margin: 0px 5px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    /* border: 1px solid; */
}

.grante-img {
    height: 24px;
    width: 24px;
}

.grante-data-mail {
    color: #2F3238;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 18px;
    margin-top: 10px;
}

.grante-wrap {
    width: 300px;
    height: 170px;
    padding: 5px;
}

.data-ingestion-description {
    color: #2F3238;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 18px;
}

.bg-unread {
    background: var(--neutral-colours-grey-50, #F3F3F3);
}

.bg-white {
    background: #fff;
}
</style>