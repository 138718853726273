<template>
    <section class="right-loghistory-drawer">
        <div class="form-header">
            <div class="loghistory-header">
                <span class="loghistory">Log History</span>
            </div>
            <div>
                <img class="move-right-icon" src="../assets/icons/move_right_icon.svg" />
            </div>
        </div>
        <div class="histories">
            <main class="main-container">
                <div class="date-logs">
                    <div class="date">28 February 2024</div>
                    <ul>
                        <li class="details-history">
                            <div class="trim">9:30:21AM</div>
                            <div class="details-log">Logged onto the platform and HR Board selected</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:32:22AM</div>
                            <div class="details-log">Search was used to navigate to the employee attendance</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:32:22AM</div>
                            <div class="details-log">Time period of the past 3 days is selected i.e, 16th Mar, 17th Mar,
                                18th Mar, to for employees who have been late to work by an hour or more.</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:32:22AM</div>
                            <div class="details-log">PI captures *the sequence of filter options selected, the specific
                                nodes and edges selected, and any other contextual information that might be relevant to
                                this interaction*</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:45:45AM</div>
                            <div class="details-log">A specific cluster of employees is identified who have been late to
                                work for the past 3 consecutive days i.e, 16th Mar, 17th Mar, 18th Mar.</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:50:22AM</div>
                            <div class="details-log">The data for selected employee has been exported out of PI</div>
                        </li>
                    </ul>
                </div>
                <div class="date-logs">
                    <div class="date">11 December 2023</div>
                    <ul>
                        <li class="details-history">
                            <div class="trim">9:30:21AM</div>
                            <div class="details-log">Logged onto the platform and HR Board selected</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:32:22AM</div>
                            <div class="details-log">Search was used to navigate to the employee attendance</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:32:22AM</div>
                            <div class="details-log">Time period of the past 3 days is selected i.e, 16th Mar, 17th Mar,
                                18th Mar, to for employees who have been late to work by an hour or more.</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:32:22AM</div>
                            <div class="details-log">PI captures *the sequence of filter options selected, the specific
                                nodes and edges selected, and any other contextual information that might be relevant to
                                this interaction*</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:45:45AM</div>
                            <div class="details-log">A specific cluster of employees is identified who have been late to
                                work for the past 3 consecutive days i.e, 16th Mar, 17th Mar, 18th Mar.</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:50:22AM</div>
                            <div class="details-log">The data for selected employee has been exported out of PI</div>
                        </li>
                    </ul>
                </div>
                <div class="date-logs">
                    <div class="date">01 September 2023</div>
                    <ul>
                        <li class="details-history">
                            <div class="trim">9:30:21AM</div>
                            <div class="details-log">Logged onto the platform and HR Board selected</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:32:22AM</div>
                            <div class="details-log">Search was used to navigate to the employee attendance</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:32:22AM</div>
                            <div class="details-log">Time period of the past 3 days is selected i.e, 16th Mar, 17th Mar,
                                18th Mar, to for employees who have been late to work by an hour or more.</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:32:22AM</div>
                            <div class="details-log">PI captures *the sequence of filter options selected, the specific
                                nodes and edges selected, and any other contextual information that might be relevant to
                                this interaction*</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:45:45AM</div>
                            <div class="details-log">A specific cluster of employees is identified who have been late to
                                work for the past 3 consecutive days i.e, 16th Mar, 17th Mar, 18th Mar.</div>
                        </li>
                        <li class="details-history">
                            <div class="trim">9:50:22AM</div>
                            <div class="details-log">The data for selected employee has been exported out of PI</div>
                        </li>
                    </ul>
                </div>
            </main>
        </div>
        <div class="snapshots">
            <button class="create-snapshot">Create Snapshot
            </button>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            clicked: false
        }
    }
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 5px;
}

.right-loghistory-drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 312px;
    height: 100%;
    border: 1px solid #eaeaea;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    transition: transform 0.3s ease;
}

.right-loghistory-drawer button,
.right-loghistory-drawer input,
.right-loghistory-drawer label {
    cursor: pointer;
}

.right-loghistory-drawer.loghistory-drawer-closed {
    width: 0;
}

.form-header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EAEAEA;
    /* max-width: calc(100% - 10vh); */
}

.loghistory-header {
    padding: 5px;
}

.move-right-icon {
    padding: 5px;
}

.loghistory {
    color: #2C2C2C;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.histories {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 67px);
}

.snapshots {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-snapshot {
    width: calc(100% - 6px);
    height: 30px;
    border-radius: 4px;
    background: #1575E1;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: 'Plus Jakarta Sans', sans-serif;
    border: none;
}

.main-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 0 16px;
}

.date {
    padding: 14px 0;
    color: var(--bg-text, #2C2C2C);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.details-history {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
}

ul:before {
    content: "";
    display: inline-block;
    width: 2px;
    background-color: #828282;
    opacity: .2;
    position: absolute;
    left: 3px;
    top: 5px;
    height: calc(100% - 10px);
}

ul li:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border: 2px solid #828282;
    background: white;
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 10px;
}

.trim {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: #2C2C2C;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.details-log {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #2C2C2C;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
</style>