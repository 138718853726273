<template>
    <section class="landing-page">
        <section>
            <TopSidebar />
            <section class="container">
                <LeftSidebar />
                <div class="content">
                    <router-view></router-view>
                </div>
            </section>
            <section class="container">
                <div class="content">
                    <router-view></router-view>
                </div>
                <RightSidebar />
            </section>
            <BottomSidebar />
        </section>
    </section>
</template>
<script>
import TopSidebar from '../components/Topsidebar.vue';
import LeftSidebar from '../components/Leftsidebar.vue';
import RightSidebar from '../components/Rightsidebar.vue';
import BottomSidebar from '../components/Bottomsidebar.vue';

export default {
    components: {
        TopSidebar,
        LeftSidebar,
        RightSidebar,
        BottomSidebar
    }
}
</script>
<style scoped>
</style>
