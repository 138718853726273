<template>
    <section class="right-mobiusai-drawer">
        <div v-if="chatgptValue" class="form-header">
            <div class="mobius-container">
                <div>
                    <img class="move-right-icon" src="../assets/icons/artificial_intelligence_robot.svg" />
                </div>
                <div class="mobius-ai-header">
                    <span class="mobius-ai">All AI Tools</span>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="form-header" v-if="!isInstructCard || backButtonValue">
                <div class="mobius-container">
                    <div>
                        <img class="move-right-icon" src="../assets/icons/chatgpt_icon.svg" alt="Chatgpt Icon" />
                    </div>
                    <div class="mobius-ai-header">
                        <span class="mobius-ai">Chatgpt</span>
                    </div>
                    <div class="back-image">
                        <img class="move-right-icon" src="../assets/icons/move_right_icon.svg" @click="backBtn" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="chatgptValue" class="mobius-ai-cards">
            <div class="cards">
                <div class="chatgpt-card" @click="chatgptCard">
                    <div class="inner-con">
                        <div class="inner-con-header">
                            <img src="../assets/icons/chatgpt_icon.svg" alt="Chatgpt Icon" />
                            <span class="card-name">Chatgpt</span>
                            <span class="premium">Premium</span>
                        </div>
                        <div class="numbers-action">
                            <div class="action">12
                            </div>
                            <div class="credits">15
                            </div>
                            <div class="credit-left">4
                            </div>
                        </div>
                        <div class="point-name">
                            <span>Actions</span>
                            <span>Credits</span>
                            <span>Credits-left</span>
                        </div>
                    </div>
                </div>
                <div class="bard-card" @click="bardCard">
                    <div class="inner-con">
                        <div class="inner-con-header">
                            <img src="../assets/icons/bard_icon.svg" alt="Bard Icon" />
                            <span class="card-name">Bard</span>
                            <button class="renew-plan">Renew plan</button>
                        </div>
                        <div class="numbers-action">
                            <div class="action">12
                            </div>
                            <div class="credits">15
                            </div>
                            <div class="credit-left">4
                            </div>
                        </div>
                        <div class="point-name">
                            <span>Actions</span>
                            <span>Credits</span>
                            <span>Credits-left</span>
                        </div>
                    </div>
                </div>
                <div class="iaskai-card" @click="iaskaiCard">
                    <div class="inner-con">
                        <div class="inner-con-header">
                            <img src="../assets/icons/iask_ai_icon.svg" alt="iAsk Ai Icon" />
                            <span class="card-name">iAsk.ai</span>
                            <button class="renew-plan">Renew plan</button>
                        </div>
                        <div class="numbers-action">
                            <div class="action">12
                            </div>
                            <div class="credits">15
                            </div>
                            <div class="credit-left">4
                            </div>
                        </div>
                        <div class="point-name">
                            <span>Actions</span>
                            <span>Credits</span>
                            <span>Credits-left</span>
                        </div>
                    </div>
                </div>
                <div class="youchat-card" @click="youchatCard">
                    <div class="inner-con">
                        <div class="inner-con-header">
                            <img src="../assets/icons/youchat_icon.svg" alt="Youchat Icon" />
                            <span class="card-name">Youchat</span>
                            <button class="renew-plan">Renew plan</button>
                        </div>
                        <div class="numbers-action">
                            <div class="action">12
                            </div>
                            <div class="credits">15
                            </div>
                            <div class="credit-left">4
                            </div>
                        </div>
                        <div class="point-name">
                            <span>Actions</span>
                            <span>Credits</span>
                            <span>Credits-left</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="mobius-ai-cards" v-if="!isInstructCard || backButtonValue">
                <div class="cards">
                    <div class="chatgpt-card" @click="instructCard">
                        <div class="details">
                            <div class="block-cointainer">
                                <button class="star-inserted">13 Done</button>
                                <label class="instruct">Instruct</label>
                                <span class="instruct-content">Instruct AI to perform any action</span>
                            </div>
                            <div class="logo">
                                <img src="../assets/icons/instruct_icon.svg" alt="Instruct Icon" />
                            </div>
                        </div>
                    </div>
                    <div class="bard-card">
                        <div class="details">
                            <div class="block-cointainer">
                                <button class="star-inserted" style="background-color: rgba(1, 165, 57, 0.43);">8
                                    new</button>
                                <label class="instruct" style="color: rgb(1, 165, 57);">Automate</label>
                                <span class="instruct-content">Let AI help you by building better structure</span>
                            </div>
                            <div class="logo">
                                <img src="../assets/icons/automate_icon.svg" alt="Instruct Icon" />
                            </div>
                        </div>
                    </div>
                    <div class="iaskai-card">
                        <div class="details">
                            <div class="block-cointainer">
                                <button class="star-inserted" style="background-color: rgba(255, 182, 40, 0.62);">8
                                    new</button>
                                <label class="instruct" style="color: rgb(255, 182, 40);">Foresights</label>
                                <span class="instruct-content">Prepare ahead with these accurate insights</span>
                            </div>
                            <div class="logo">
                                <img src="../assets/icons/foresights_icon.svg" alt="Instruct Icon" />
                            </div>
                        </div>
                    </div>
                    <div class="youchat-card">
                        <div class="details">
                            <div class="block-cointainer" style="margin-top: 20px;">
                                <!-- <button class="star-inserted"></button> -->
                                <label class="instruct" style="color: rgb(16, 83, 255);">Insights</label>
                                <span class="instruct-content">Get better understanding of your current
                                    performance</span>
                            </div>
                            <div class="logo">
                                <img src="../assets/icons/insights_icon.svg" alt="Instruct Icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isInstructCard" class="form-header">
            <div class="mobius-container">
                <div class="back-image-instruct">
                    <img class="move-right-icon" src="../assets/icons/back_button_icon.svg" @click="backBtn" />
                    <span class="instruct" style="font-weight:400;color:#2C2C2C;">Instruct</span>
                </div>
                <div class="mobius-ai-header">
                    <span class="mobius-ai">Chatgpt</span>
                </div>
                <div>
                    <img class="move-right-icon" src="../assets/icons/chatgpt_icon.svg" alt="Chatgpt Icon" />
                </div>
            </div>
        </div>
        <section class="instrcut-cont">
            <div class="message-cont" v-if="messagedPassedValue">
                <div class="message-form">
                    <div class="icon-container">
                        <img src="../assets/icons/open_ai_icon.svg" />
                    </div>
                    <div class="text-container">
                        <label>Hi there,</label>
                        <label>Let me help you with any task on Rink.</label>
                        <label>Just ask what you need!</label>
                    </div>
                </div>
                <div class="message-to">{{ dataValue }}
                </div>
            </div>
            <div class="text-message">
                <input type="text" placeholder="Type your request here" class="text-message" v-model="inputValue">
                <img src="../assets/icons/microphone_icon.svg" class="microphone" />
            </div>
            <div class="submit-btns-container">
                <button class="reset-btn">
                    <img src="../assets/icons/discard_cross_icon.svg" alt="cross" />Discard
                </button>
                <button class="create-btn" @click="sendAll">
                    <img src="../assets/icons/check_icon.svg" alt="check right" />Enter
                </button>
            </div>
        </section>
    </section>
</template>
<script>
export default {
    data() {
        return {
            chatgptValue: true,
            backButtonValue: false,
            isInstructCard: false,
            messagedPassedValue: false,
            dataValue: '',
            inputValue: '',
        }
    },
    methods: {
        chatgptCard() {
            console.log("Chatgpt Card");
            this.chatgptValue = false
        },
        bardCard() {
            console.log("Bard Card");
            this.chatgptValue = false
        },
        iaskaiCard() {
            console.log("iAsk.ai Card");
            this.chatgptValue = false
        },
        youchatCard() {
            console.log("Youchat Card");
            this.chatgptValue = false
        },
        backBtn() {
            console.log("Back Button");
            this.isInstructCard = false

        },
        instructCard() {
            console.log("Instruct Card");
            this.isInstructCard = true

        },
        sendAll() {
            console.log("Send clicked");
            this.messagedPassedValue = true,
                this.dataValue = this.inputValue;
            this.inputValue = '';
        }
    }
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 5px;
}

.right-mobiusai-drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 312px;
    height: 100%;
    border: 1px solid #eaeaea;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.right-loghistory-drawer button,
.right-loghistory-drawer input,
.right-loghistory-drawer label {
    cursor: pointer;
}

.right-loghistory-drawer.loghistory-drawer-closed {
    width: 0;
}

.form-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 0 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.mobius-container {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    border-bottom: 1px solid #EAEAEA;
}

.mobius-ai-header {
    padding: 5px;
}

.move-right-icon {
    padding: 5px;
}

.back-image {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.back-image-instruct {
    display: flex;
    flex: 1;
    align-items: center;
}

.mobius-ai {
    color: #2C2C2C;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
}

.mobius-ai-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.cards {
    padding: 10px;
}

.chatgpt-card,
.bard-card,
.iaskai-card,
.youchat-card {
    width: 280px;
    height: 120px;
    border-radius: 8px;
    border: 1px solid rgba(161, 161, 161, .1333333333);
    background-color: rgba(161, 161, 161, .1);
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.inner-con {
    width: 264px;
    height: 98px;
    margin: 12px 8px;
}

.inner-con-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.card-name {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    color: #2C2C2C;
    cursor: pointer;
}

.renew-plan {
    margin-left: auto;
    border-radius: 4px;
    border: 1px solid #1575E1;
    background-color: #1575e1;
    color: #fff;
    padding: 5px;
}

.premium {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    color: #000000;
    cursor: pointer;

}

.numbers-action,
.point-name {
    display: flex;
    opacity: 0.7;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    color: #2C2C2C;
    cursor: pointer;
}

.action,
.credits,
.credit-left {
    width: 83px;
    height: 36px;
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
    font-family: 'Roboto', sans-serif;
    color: #2C2C2C;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.numbers-action>div,
.point-name>span {
    flex: 1;
    text-align: center;
}

.logo {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.details {
    display: flex;
    justify-content: space-evenly;
    /* border: 1px solid; */
}

.block-cointainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.star-inserted {
    border-radius: 16px;
    font-family: 'Roboto', sans-serif;
    padding: 0 10px;
    border: 1px solid rgba(224, 72, 38, .2);
    background-color: #e0482633;
    margin: 0 10px;
}

.instruct {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #E04826;
    line-height: 16px;
    margin: 0 10px;
}

.instruct-content {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #2C2C2C;
    line-height: 16px;
    margin: 0 10px;
}

.instrcut-cont {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.message-cont {
    overflow-y: scroll;
}

.text-message {
    border-radius: 4px;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #f9f9f9;
    height: 64px;
}

.bottom-section {
    position: absolute;
    bottom: 0;
    height: 32px;
    display: flex;
    width: 100%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    box-shadow: 0 -2px 8px #00000014;
}

.text-message {
    color: #2c2c2c;
    opacity: 50%;
    border: none;
    background-color: #f9f9f9;
    height: 20px;
    border-radius: 4px;
    display: flex;
    font-weight: 300;
    width: 100%;
    padding: 0 5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    text-align: start;
    height: 62px;
    outline: none;
}

.microphone {
    display: flex;
    width: 24px;
    vertical-align: middle;
}

.submit-btns-container {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    box-shadow: 0 -2px 8px #00000014;
}

.submit-btns-container .reset-btn {
    width: 40%;
}

.submit-btns-container button {
    border: none;
    padding: 0.5em 1em;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.submit-btns-container .reset-btn img {
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 5px;
}

.submit-btns-container .create-btn {
    width: 60%;
    background-color: #00a1ed;
    color: #fff;
}

.submit-btns-container .create-btn img {
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 5px;
}

.message-to {
    border-radius: 4px;
    background: rgba(249, 249, 249, .9);
    color: #000;
    width: 280px;
    height: 48px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    padding: 10px;
    margin: 0 15px 20px;
    position: relative;
}

.message-form {
    background-color: #E8EAFF;
    color: #363636;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    width: 280px;
    height: 85px;
    padding: 10px;
    border-radius: 8px;
    margin: 0 15px 20px;
}

.text-container label {
    display: block;
}
</style>