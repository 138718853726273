<template>
    <div class=bottom-bar>
        <div class="left-div">
            <div class="left-div-1">
                <img height="24px" width="24px" src="../assets/icons/card_view_icon.svg" alt="Card View" />
                <span class="text-wrap-white">Card View</span>
            </div>
            <div class="left-div-2">
                <img height="24px" width="24px" src="../assets/icons/map_view_icon.svg" alt="Map View" />
                <span class="text-wrap">Map View</span>
            </div>
            <div class="left-div-3">
                <img height="24px" width="24px" src="../assets/icons/table_view_icon.svg" alt="Table View" />
                <span class="text-wrap">Table View</span>
            </div>
        </div>
        <div class="right-div">
            <div class="right-div-1">
                <span>100%</span>
                <img src="../assets/icons/focus_icon.svg" alt="Focus Icon" />
                <img width="18px" height="19px" src="../assets/icons/horizontal_slider_icon.svg"
                    alt="Horizontal Slider Icon" />
                <img src="../assets/icons/help_chat_icon.svg" alt="Help Chat Icon" />
            </div>
            <div class="right-div-2">
                <img src="../assets/icons/button_loop_icon.svg" alt="Button Loop Icon" />
                <div class="mobius-flow">
                    <span>Mobius Flow</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

</script>
<style scoped>
.bottom-bar {
    align-items: center;
    width: calc(100% - 100px);
    margin: 0 50px; 
    border-top: 2px solid #0387D3 !important;
    position: absolute;
    bottom: 0;
    display: flex;
    height: 32px;
    background: #ffffff;
    border-top: 1px solid #EAEAEA;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: hidden;
}

.left-div {
    width:50%;
    height: 30px;
    display: flex;
    gap: 15px;
    margin: 3px 10px;

}

.left-div-1 {
    width: 131px;
    height: 28px;
    background-color: #0387D3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: skew(-20deg);
}

.left-div-2 {
    width: 100px;
    height: 30px;
    color: #424750;
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
}

.left-div-3 {
    width: 102px;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
}

.text-wrap-white {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.text-wrap {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #424750;
}

.right-div {
    width: 50%;
    height: 24px;
    display: flex;
    justify-content: flex-end; 
    gap: 10px;
    margin: 3px 10px;
}

.right-div-1 {
    width: 164px;
    height: 24px;
    gap: 10px;
    border-right: 1px solid #D5D7DB;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.right-div-2 {
    width: 130px;
    height: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.mobius-flow {
    width: 72px;
    height: 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    font-family: Inter;
    white-space: nowrap;
    overflow: hidden;
}
</style>