<template>
    <div class="top-bar">
        <div class="heading">
            <div class="left-div">
                <span class="all-exp">All Experiences</span>
            </div>
            <div class="right-div">
                <span class="mobius-dtaas">Mobius DTaaS</span>
                <img src="../assets/icons/data_info_icon.svg" alt="More Info" />
            </div>
        </div>
        <div class="input-container">
            <input type="text" class="input-title" placeholder="Search" required>
            <img height="16px" width="16px" src="../assets/icons/search_icon.svg" alt="Search Icon" />
        </div>
    </div>
</template>
<script>

</script>
<style scoped>
.top-bar {
    border-top: 2px solid #EAEAEA;
    border-bottom: 2px solid #EAEAEA;
    align-items: center;
    transition: ease-in-out .3s;
    display: flex;
    width: calc(100% - 100px);
    margin: 0 50px;
    position: relative;
}

.input-container {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    align-items: center;
    gap: 12px;
    padding: 5px 10px;
}

.input-title {
    flex-grow: 1;
    height: 24px;
    width: 49px;
    border: none;
    font-size: 14px;
    color: #545454;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
}

.heading {
    width:  531px;
    height: 24px;
    display: flex;
    justify-content: space-between;
}

.left-div {
    width:176px;
    height: 24px;
}

.all-exp {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    color: #424750;
    padding: 5px 10px;
}

.right-div {
    width: 135px;
    height: 24px;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px;
}

.mobius-dtaas {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    color: #000000
}
</style>