<template>
    <div>
        <section class="left-bar">
            <div class="sidebar">
                <div class="mobius">
                    <div class="mobius-icon">
                        <img src="../assets/icons/mobius_icon.svg" alt="Mobius Icon" />
                    </div>
                </div>
                <main class="main">
                    <div class="content-container" :style="{ height: contentHeight + 'px' }">
                        <div class="mobius-portals">
                            <div class="protal-icons">
                                <div class="menu-cursor" tabindex="0" @click="holacracyInsights">
                                    <img class="portal-img" src="../assets/icons/holacracy_icon.svg"
                                        alt="Holacracy Icon" />
                                    <span class="portal-text">Holacracy
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="protal-icons">
                                    <div v-if="holacracyChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/news_icon.svg" alt="News Icon" />
                                        <span class="portal-text">In The News
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="holacracyChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/storefront_icon.svg"
                                            alt="Storefront Icon" />
                                        <span class="portal-text">Storefront
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="holacracyChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/alliance_icon.svg"
                                            alt="Alliance Icon" />
                                        <span class="portal-text">Alliance
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="holacracyChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/message_icon.svg"
                                            alt="Message Icon" />
                                        <span class="portal-text">Messages
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="holacracyChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/configure_icon.svg"
                                            alt="Configure Icon" />
                                        <span class="portal-text">Configure
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="protal-icons">
                                <div class="menu-cursor" tabindex="0" @click="monetInsights">
                                    <img class="portal-img" src="../assets/icons/monet_icon.svg" alt="Monet Icon" />
                                    <span class="portal-text">Monet
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="protal-icons">
                                    <div v-if="monetChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/cms_icon.svg" alt="CMS Icon" />
                                        <span class="portal-text">CMS
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="monetChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/my_experiences_icon.svg"
                                            alt="My Experiences Icon" />
                                        <span class="portal-text">My Experiences
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="monetChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/applets_icon.svg"
                                            alt="Applets Icon" />
                                        <span class="portal-text">Applets
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="monetChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/widgets_icon.svg"
                                            alt="Message Icon" />
                                        <span class="portal-text">Widgets
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="protal-icons">
                                <div class="menu-cursor" tabindex="0" @click="vinciInsights">
                                    <img class="portal-img" src="../assets/icons/vinci_icon.svg" alt="Vinci Icon" />
                                    <span class="portal-text">Vinci
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="protal-icons">
                                    <div v-if="vinciChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/user_experience_icon.svg"
                                            alt="User Experiences Icon" />
                                        <span class="portal-text">User Experiences
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="vinciChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/coaching_plan_icon.svg"
                                            alt="Coaching Plan Icon" />
                                        <span class="portal-text">Coaching Plan
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="vinciChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/horizontal_slider_icon.svg"
                                            alt="Horizontal Slider Icon" />
                                        <span class="portal-text">Horizontal Slider
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="protal-icons">
                                <div class="menu-cursor" tabindex="0" @click="bobInsights">
                                    <img class="portal-img" src="../assets/icons/bob_icon.svg" alt="BoB Icon" />
                                    <span class="portal-text">BoB
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="protal-icons">
                                    <div v-if="bobChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/brick_icon.svg"
                                            alt="Bricks Icon" />
                                        <span class="portal-text">Bricks
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="bobChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/listener_icon.svg"
                                            alt="Listeners Icon" />
                                        <span class="portal-text">Listeners
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="bobChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/workflow_icon.svg"
                                            alt="Workflows Icon" />
                                        <span class="portal-text">Workflows
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="bobChilds" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/packages_icon.svg"
                                            alt="Packages Icon" />
                                        <span class="portal-text">Packages
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="protal-icons">
                                <div class="menu-cursor" tabindex="0" @click="piInsights">
                                    <img class="portal-img" src="../assets/icons/pi_icon.svg" alt="PI Icon" />
                                    <span class="portal-text">PI
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="protal-icons">
                                    <div v-if="piChild" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/dataverses.svg"
                                            alt="Dataverses Icon" />
                                        <span class="portal-text">Dataverses
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="piChild" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/data_ingests.svg"
                                            alt="Data Ingests Icon" />
                                        <span class="portal-text">Data Ingests
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="piChild" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/entity_models.svg"
                                            alt="Entity Models Icon" />
                                        <span class="portal-text">Entity Models
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="piChild" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/cohorts.svg" alt="Cohorts Icon" />
                                        <span class="portal-text">Cohorts
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="piChild" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/big_queries.svg"
                                            alt="Big Queries Icon" />
                                        <span class="portal-text">Big Queries
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="piChild" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/contexts.svg"
                                            alt="Contexts Icon" />
                                        <span class="portal-text">Contexts
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="piChild" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/visualisations.svg"
                                            alt="Visuslisations Icon" />
                                        <span class="portal-text">Visuslisations
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="piChild" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/engagements.svg"
                                            alt="Engagements Icon" />
                                        <span class="portal-text">Engagements
                                        </span>
                                    </div>
                                </div>
                                <div class="protal-icons">
                                    <div v-if="piChild" class="menu-cursor-child" tabindex="0">
                                        <img class="portal-img" src="../assets/icons/ml_models.svg"
                                            alt="ML Models Icon" />
                                        <span class="portal-text">ML Models
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div class="bottom">
                    <div class="user-icon-con">
                        <img class="user-img" src="../assets/icons/request_image.svg" alt="User Image" />
                        <span class="profile-btn">My Account</span>
                    </div>
                    <p class=powered-by>Powered by</p>
                    <div class="icon-con">
                        <img class="mobius-black" src="../assets/icons/mobius_white_icon.svg" alt="Mobius Icon" />
                        <span class="profile-btn">Sign out</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            holacracyChilds: false,
            contentHeight: '',
            monetChilds: false,
            bobChilds: false,
            piChild: false,
            vinciChilds: false
        }

    },
    methods: {
        holacracyInsights() {
            this.holacracyChilds = !this.holacracyChilds;
            this.contentHeight = this.holacracyChilds ? 580: '';
        },
        monetInsights() {
            this.monetChilds = !this.monetChilds;
        },
        bobInsights() {
            this.bobChilds = !this.bobChilds
        },
        piInsights() {
            this.piChild = !this.piChild
        },
        vinciInsights() {
            this.vinciChilds = !this.vinciChilds
        }
    }
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 5px;
}

.left-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #dde5e9;
    border-right: 1px solid #dde5e9;
    background: #fff;
    width: 49px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 1;
    transition: right 0.3s ease;
}

.sidebar {
    position: absolute;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    left: 0;
    top: 0;
    height: 100vh;
    width: 50px;
    z-index: 999;
    transition: width .3s;
    overflow: hidden;
    background: #FFFFFF;
    border-right: 2px solid #EAEAEA;
    display: flex;
    flex-direction: column;
}

.sidebar>div:last-child {
    margin-top: auto;
}

.mobius-icon {
    width: 32px;
    height: 32px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}

.mobius {
    margin: 8px 8px 15px;
}

.main {
    display: flex;
    flex-direction: column;
    margin: 0 2px;
    max-height: calc(100vh - 20%);
}

.content-container {
    display: flex;
    flex-direction: column;
}

.mobius-portals {
    margin: 0px 8.5px 5px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.portal-icons {
    position: relative;
    transition: border .3s;
}

.line {
    margin: 11px 0;
    border-top: 2px solid #CBCBCB;
    border-radius: 10px;
    width: 100%;
}

.menu-cursor {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.menu-cursor:hover {
    background-color: lightgray;
    font-weight: bold;
}

.portal-text {
    position: absolute;
    left: 45px;
}

.portal-img {
    width: 22px;
    height: 26px;
}

.sidebar:hover {
    width: 192px;
}

.bottom {
    position: relative;
    width: 100%;
    border-top: 2px solid #CBCBCB;
    margin: 0 0px 6px;
}

.icon-con {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 5px 0 5px 8px;
}
.user-icon-con{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 12px 0 0 8px;
}
.icon-con:hover {
    background-color: lightgray;
    font-weight: bold;
}
.user-icon-con:hover{
    background-color: lightgray;
    font-weight: bold;
}
.powered-by {
    margin: 15px 0 0 12px;
    color: #797979;
    font-family: Inter;
    font-size: 6px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -.132px;
}

.profile-btn {
    position: absolute;
    width: 150px;
    left: 55px;
    transition: color .3s;
    color: #000;
}

.mobius-black {
    filter: invert(1);
    width: 34px;
}

.user-img {
    width: 34px;
    height: 27px;
}

.menu-cursor-child {
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.protal-icons {
    position: relative;
    transition: border .3s;
}
</style>