<template>
  <div id="app">
    <!-- <Rightsidebar></Rightsidebar> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import Rightsidebar from './components/Rightsidebar.vue'


export default {
  // components: {
  //   Rightsidebar,
  // }
};
</script>
<style>
.app{
  overflow:hidden ;
}
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
</style>