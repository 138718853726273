import { createRouter, createWebHistory } from 'vue-router';
import Canvas from '../views/Canvas.vue';
import LoginComponent from '../components/Login.vue'

const routes = [
  {
    path: '/',
    component: Canvas
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginComponent,
    props: true
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});
  
export default router;