<template>
    <div class="main-route">
        <section class="login-bg">
            <div class="sub-bg">
                <div class="container">
                    <div class="row">
                        <div class="login-container">
                            <div class="login-mobius">
                                <div class="login-pannel">
                                    <div class="mobius-img">
                                        <img src="../assets/icons/mobius_icon.svg" height="147" width="233" />
                                    </div>
                                    <div class="inner-login-mobius">
                                        <div class="welcome-con">
                                            <span class="welcome">Welcome back</span>
                                        </div>
                                        <div class="email-con">
                                            <span class="email-address">Email Address</span>
                                            <div class="email-input-container">
                                                <input class="email-input" placeholder="Email address" v-model="email"
                                                    @input="validateEmail" />
                                                <div class="circle">
                                                    <img v-if="isEmailValid" src="../assets/icons/check_right_mark.svg"
                                                        width="22.5" height="22.5" alt="Valid Email" />
                                                    <img v-else-if="email" src="../assets/icons/cross_mark.svg"
                                                        width="22.5" height="22.5" alt="Invalid Email" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="password-con">
                                            <span class="password">Password</span>
                                            <div class="password-input-container">
                                                <input class="password-input" placeholder="Password" v-model="password"
                                                    :type="isPassword ? 'password' : 'text'" />
                                                <div class="password-img" :class="{ 'disabled': isPasswordEmpty }">
                                                    <img src="../assets/icons/password-img.svg" width="25" height="25"
                                                        v-if="isPassword" @click="hidePassword" alt="Hide password" />
                                                    <img v-else src="../assets/icons/visible_password_icon.svg"
                                                        width="25" height="25" @click="visiblePassword"
                                                        alt="Show password" />
                                                </div>
                                            </div>
                                            <div class="remember">
                                                <div class="remember-circle">
                                                    <div class="password-circle"></div>
                                                    <span class="remember-me">Remember me</span>
                                                </div>
                                                <span class="forget-password">Forget password?</span>
                                            </div>
                                        </div>
                                        <div class="login-con">
                                            <div class="login-options">
                                                <span class="login-with">or login with</span>
                                                <div class="options">
                                                    <button class="option-btn">
                                                        <img src="../assets/icons/apple-icon.svg" />
                                                    </button>
                                                    <button class="option-btn">
                                                        <img src="../assets/icons/facebook-icon.svg" />
                                                    </button>
                                                    <button class="option-btn">
                                                        <img src="../assets/icons/google-icon.svg" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="login-btn">Log in</button>
                                        <div class="content">
                                            <div>
                                                <span class="message-content">Don't have an account? <span
                                                        class="sign-up">Sign up
                                                    </span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <footer class="footer-text">
                                        <span>@2010 2023 Gaian Technologies</span>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            email: '',
            isEmailValid: false,
            isPassword: true,
            password: '',
        };
    },
    components: {
    },
    computed: {
        isPasswordEmpty() {
            return this.password.length === 0;
        },
    },
    methods: {
        hidePassword() {
            if (!this.isPasswordEmpty) {
                this.isPassword = !this.isPassword
            }
        },
        visiblePassword() {
            this.isPassword = !this.isPassword
        },
        validateEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.isEmailValid = emailPattern.test(this.email);
        },
    }
}
</script>
<style scoped>
.main-route {
    position: absolute;
    z-index: -1;
}

.login-bg {
    background-image: url('../assets/icons/background_login_icon.png');
    background-size: contain;
    width: 100vw;
}

.sub-bg {
    height: 100vh;
    background-image: url('../assets/icons/mobius_logo.png');
    background-repeat: no-repeat;
    background-size: 45%;
    background-position-x: right;
    background-position-y: 23%;
    width: 100%;
    overflow: hidden;
}

/* @media(min-width: 1360px) {
    .container {
        max-width: 1140px;
    }
}

@media(min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media(min-width: 768px) {

    .container-md,
    .container-sm,
    .container {
        max-width: 720px;
    }
} */

/* @media (min-width: 576px) {

    .container-sm,
    .container {
        max-width: 540px;
    }
} */

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: 100vh;
}

.row {
    row-gap: 15px;
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-left: 152px;
}

.login-container {
    width: auto;
    height: auto;
}

@media (min-width: 576px) {
    .login-mobius {
        flex: 0 0 auto;
        width: 100%;
    }
}

.login-mobius {
    height: 800px;
    width: 375px;
    margin-top: 10vh;
    gap: 18px;
    border-radius: 10px;
}

.login-pannel {
    height: 100vh;
}

.mobius-img {
    text-align: center;
}

.inner-login-mobius {
    width: 375px;
    height: 699px;
    text-align: center;
}

.welcome-con {
    /* width:210px; */
    /* height:38px; */
    margin-top: 16px;
}

.welcome {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 37.5px;
    color: #202020;
}

.email-con {
    width: 327px;
    height: 80px;
    margin-top: 30px;
    margin-left: 24px;
    gap: 4px;
    display: block;
}

.email-address {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
    display: block;
    text-align: left;
    margin-bottom: 5px;
}

.email-input-container,
.password-input-container {
    position: relative;
    display: inline-flex;
}

.email-input {
    width: 327px;
    height: 60px;
    justify-content: space-between;
    padding: 18px 45px 18px 16px;
    background-color: #F1F1F1;
    border: none;
    outline: none;
    display: block;
    text-align: left;
    box-sizing: border-box;
}

.circle {
    width: 22.5px;
    height: 22.5px;
    margin-top: 0.75px;
    margin-left: 0.75px;
    border: 1.5px solid #898989;
    position: absolute;
    top: 30%;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
}

.password-con {
    width: 327px;
    height: 120px;
    margin-top: 30px;
    margin-left: 24px;
    padding: 0px 0px 16px 0px;
    gap: 4px;
    display: block;
}

.password {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
    display: block;
    text-align: left;
    margin-bottom: 5px;
}


.password-input {
    width: 327px;
    height: 60px;
    justify-content: space-between;
    padding: 18px 45px 18px 16px;
    background-color: #F1F1F1;
    border: none;
    outline: none;
    display: block;
    text-align: left;
    box-sizing: border-box;
}

.password-img {
    margin-top: 0.75px;
    margin-left: 0.75px;
    position: absolute;
    top: 30%;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.remember {
    width: 327px;
    height: 16px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.remember-circle {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.password-circle {
    width: 16px;
    height: 16px;
    margin-top: 0.75px;
    margin-left: 0.75px;
    border: 1.5px solid #898989;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
}

.remember-me,
.forget-password,
.login-with {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
}

.login-con {
    width: 327px;
    height: 126px;
    padding: 16px 0px 16px 0px;
    gap: 8px;
    margin-top: 30px;
    margin-left: 24px;
}

.login-options {
    padding: 0px 0px 16px 0px;
    width: 327px;
    height: 94px;
    gap: 22px;
}

.options {
    display: flex;
    width: 327px;
    height: 56px;
    margin-top: 20px;
    gap: 10px;
}

.option-btn {
    border: 1px solid #000000;
    width: 102.33px;
    height: 56px;
    gap: 16px;
    border-radius: 8px;
    background-color: #fff;
}

.login-btn {
    width: 327px;
    height: 56px;
    margin-top: 30px;
    border-radius: 8px;
    gap: 10px;
    background-color: #9747FF;
    border: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 18.75px;
    align-items: center;
    color: #FFFFFF;
}

.content {
    width: 327px;
    height: 52px;
    margin-left: 24px;
    margin-top: 25px;
}

.message-content {
    font-weight: 400;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 20.16px;
}

.sign-up {
    font-weight: 700;
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 18.75px;
}

.footer-text {
    position: absolute;
    bottom: 2vh;
    left: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;
    color: #808080;
}
</style>