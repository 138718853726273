<template>
    <section class="right-drawer">
        <div class="form-header">
            <div class="marketpalce-header">
                <span class="marketplace-management">Marketplace Management</span>
            </div>
            <div class="img-right">
                <img src="../assets/icons/pin_icon.svg" />
            </div>
        </div>
        <!-- Two tabs  -->
        <div class="tabs">
            <button class="product-tab">Product</button>
            <button class="marketplace-tab">Marketplace</button>
        </div>
        <div class="container">
            <div class="left-tab">
                <div class="uuid">
                    <span class="refresh-btn">UUID / URI
                        <img src="../assets/icons/refresh_icon.svg" style="vertical-align: middle;" />
                    </span>
                    <input id="uuId" type="text" class="input-uuid" v-model="uuid">
                </div>
                <div class="publish-box">
                    <span class="heading-wrapper">Publish Options</span>
                    <div class="checkboxes">
                        <label style="display: inline-block;">
                            <input type="checkbox" v-model="groupConnectedConstructs" true-value="yes"
                                false-value="no" />
                            <span class="checkbox-label">Group Connected Constructs</span>
                        </label>
                        <div class="additional-info">
                            <span class="connected-construct">3 Connected constructs</span>
                            <span class="select-text">Select</span>
                        </div>
                    </div>
                    <div class="checkboxes">
                        <label style="display: inline-block;">
                            <input type="checkbox" v-model="productDefinition" true-value="yes" false-value="no" />
                            <span class="checkbox-label">Product Definition </span>
                        </label>
                    </div>
                    <div class="checkboxes">
                        <label style="display: inline-block;">
                            <input type="checkbox" v-model="productData" true-value="yes" false-value="no" />
                            <span class="checkbox-label">Product Data</span>
                        </label>
                        <div class="additional-info">
                            <span class="connected-construct">3 mb | 1200 Records</span>
                            <span class="select-text">Preview</span>
                        </div>
                    </div>
                </div>
                <div class="version-head">
                    <span class="heading-wrapper">Versioning</span>
                    <div class="radios">
                        <label style="display: inline-block;">
                            <input type="radio" v-model="selectedOption" value="option1" class="custom-radio"
                                id="option1">
                            <span for="option1" class="radio-label">Publish as new product</span>
                        </label>
                    </div>
                    <div class="radios">
                        <label style="display: inline-block;">
                            <input type="radio" v-model="selectedOption" value="option2" class="custom-radio"
                                id="option2">
                            <span for="option2" class="radio-label">Publish as version</span>
                        </label>
                    </div>
                </div>
                <div class="thumb">
                    <span class="thumbnail">Thumbnail</span>
                    <div class="thumbnail-container">
                        <div class="image" :class="{ 'flex': !fileName }">
                            <label class="upload-label" for="fileInput" v-if="!imagePreviewUrl">Drag and drop or click
                                to
                                upload</label>
                            <input id="fileInput" class="selected-img" type="file" accept="image/jpeg, image/png"
                                required @change="handleFileChange">
                            <img v-if="imagePreviewUrl" :src="imagePreviewUrl" alt="Selected Image">
                            <p class="fileName" v-if="isImage">{{ fileName }}</p>
                            <p class="fileSize" v-if="isImage">{{ imageSizeString }}</p>
                            <!-- Use a transparent overla   y to cover the entire area of the div and make it clickable -->
                            <div class="overlay" @click="triggerFileInput">
                                <div class="right-border-thumbnail"></div>
                            </div>
                        </div>
                        <img for="fileInput" class="thumb-star-icon" src="../assets/icons/mandatory_start_icon.svg" />
                    </div>
                    <div v-if="showErrorThumbnail && isInvalidThumbnail" class="error-message">Thumbnail is
                        required
                    </div>
                </div>
                <div class="input-box">
                    <span class="input-title">Product Name</span>
                    <div class="flex-container">
                        <div class="input-con">
                            <input type="text" class="input-title-label" required v-model="productName"
                                @input="validateProductName">
                        </div>
                        <div class="right-border"></div>
                        <img for="fileInput" class="star-icon" src="../assets/icons/mandatory_start_icon.svg" />
                    </div>
                    <div v-if="showErrorProductName && isInvalidProductName" class="error-message">Product Name is
                        required
                    </div>
                </div>
                <div class="input-box">
                    <span class="input-title">Product Version</span>
                    <div class="flex-container">
                        <div class="input-con">
                            <input type="text" class="input-title-label" required v-model="productVersion"
                                @input="validateProductVersion">
                        </div>
                        <div class="right-border"></div>
                        <img for="fileInput" class="star-icon" src="../assets/icons/mandatory_start_icon.svg" />
                    </div>
                    <div v-if="showErrorProductVersion && isInvalidProductVersion" class="error-message">Product Version
                        is
                        required</div>
                </div>
                <div class="input-box">
                    <span class="input-title">Product Type</span>
                    <div class="flex-container">
                        <div class="input-con">
                            <input type="text" class="input-title-label" required v-model="productType"
                                @input="validateProductType">
                        </div>
                        <div class="right-border"></div>
                        <img for="fileInput" class="star-icon" src="../assets/icons/mandatory_start_icon.svg" />
                    </div>
                    <div v-if="showErrorProductType && isInvalidProductType" class="error-message">Product Type is
                        required
                    </div>
                </div>
                <div class="input-box">
                    <span class="input-title">Description</span>
                    <div class="flex-container">
                        <textarea class="text-area" required v-model="description"></textarea>
                    </div>
                    <div class="right-border-descp"></div>
                </div>
                <div class="input-box">
                    <span class="input-title">Tags</span>
                    <div class="flex-container">
                        <div class="input-con">
                            <input type="text" class="input-title-label" v-model="fetchedTags" placeholder="Search"
                                required @input="validateTag" @click="fetchProductTags">
                        </div>
                        <div class="right-border"></div>
                        <ul class="product-tag-list" v-show="tagPopupVisible">
                            <div class="tag-popup">
                                <span class="manage-tags">Manage Tags</span>
                                <div class="search-bar">
                                    <img src="../assets/icons/tag_search_icon.svg" alt="Search Icon" />
                                    <input class="search-tag" type="text" placeholder="SearchTags"
                                        v-model="searchQuery" />
                                    <img src="../assets/icons/tag_add_icon.svg" alt="Add Icon" @click="addNewTag"
                                        style="cursor: pointer;" />
                                </div>
                                <div id="creation-tag" :class="['creation-tag', { active: isPopupVisible }]"
                                    v-if="isTagPopupVisible">
                                    <div class="create-tag-input">
                                        <svg width="11" height="14" viewBox="0 0 11 14"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.3512 8.48008C10.3512 10.9393 8.29245 12.9501 5.73125 12.9501C3.17005 12.9501 1.11125 10.9393 1.11125 8.48008C1.11125 7.65054 1.39808 6.72738 1.85157 5.79291C2.3033 4.86207 2.909 3.94308 3.52025 3.12808C4.13078 2.31404 4.74209 1.61002 5.20107 1.10932C5.41742 0.873301 5.59954 0.682879 5.73125 0.547869C5.86296 0.682879 6.04508 0.873301 6.26143 1.10932C6.72041 1.61002 7.33172 2.31404 7.94225 3.12808C8.5535 3.94308 9.1592 4.86207 9.61093 5.79291C10.0644 6.72738 10.3512 7.65054 10.3512 8.48008Z"
                                                stroke="black" stroke-width="0.66" :fill="currentFillColor" />
                                            <path d="M8.82422 8.47949C8.82422 10.1363 7.4391 11.4795 5.73047 11.4795"
                                                stroke="black" stroke-width="0.66" stroke-linecap="round" />
                                            <ellipse cx="4.18437" cy="11.1799" rx="0.309375" ry="0.3" fill="black" />
                                        </svg>
                                        <div v-if="isTagPopupVisible" class="create-tag-div">
                                            <input text="text" :placeholder="newTagName || 'Enter Name'"
                                                class="create-input" v-model="newTagName" />
                                            <img src="../assets/icons/tag_delete_icon.svg" alt="Delete Icon"
                                                class="delete-btn" @click="hideCreatedTag" />
                                        </div>
                                    </div>
                                    <div class="create-tag-color">
                                        <div class="circle" name="white" style="background-color:#FFFFFF;"
                                            :class="{ 'active-circle': selectedColor === '#FFFFFF' }"
                                            @click="updateColor('#FFFFFF')"></div>
                                        <div class="circle" name="orange" style="background-color: #FF7B51;"
                                            :class="{ 'active-circle': selectedColor === '#FF7B51' }"
                                            @click="updateColor('#FF7B51')"></div>
                                        <div class="circle" name="yellow" style="background-color: #EFFF8D;"
                                            :class="{ 'active-circle': selectedColor === '#EFFF8D' }"
                                            @click="updateColor('#EFFF8D')"></div>
                                        <div class="circle" name="green" style="background-color: #4DDA36;"
                                            :class="{ 'active-circle': selectedColor === '#4DDA36' }"
                                            @click="updateColor('#4DDA36')"></div>
                                        <div class="circle" name="blue" style="background-color: #5681D4;"
                                            :class="{ 'active-circle': selectedColor === '#5681D4' }"
                                            @click="updateColor('#5681D4')"></div>
                                        <div class="circle" name="purple" style="background-color: #DF9CFF;"
                                            :class="{ 'active-circle': selectedColor === '#DF9CFF' }"
                                            @click="updateColor('#DF9CFF')"></div>
                                        <div class="circle" name="grey" style="background-color: #ABABAB;"
                                            :class="{ 'active-circle': selectedColor === '#ABABAB' }"
                                            @click="updateColor('#ABABAB')"></div>
                                    </div>
                                </div>
                            </div>
                            <div :class="['tag-list', { active: isTagListVisible }]">
                                <ul>
                                    <li v-for="(tag, index) in filteredTagsComputed" :key="index"
                                        @click="toggleTagSelection(tag)" class="items">
                                        <div class="circle"
                                            :style="[{ backgroundColor: tag.tagColor }, tag.tagColor === '#FFFFFF' ? { border: '1px solid #000000' } : {}]">
                                        </div>
                                        <div class="item-content">
                                            <input type="checkbox" :checked="isSelected(tag)" />
                                            <label>{{ capitalizeFirstLetter(tag.tagName) }}</label>
                                        </div>
                                    </li>
                                </ul>
                                <div v-if="filteredTagsComputed.length === 0" @click="createNewTag">
                                    <div v-if="showFlexContainerTag" class="flex-container-tag" @click="customisedTag">
                                        <div class="newTag-circle"></div>
                                        <div class="create-tag">Create Tag "{{ searchQuery }}" ?</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="isPopupVisible && isTagPopupVisible"
                                :class="['bottom-save-btn', { active: isPopupVisible }]">
                                <div class="btn-discard" @click="hideCreatedTag">
                                    <span>Discard</span>
                                </div>
                                <div class="btn-save" @click="saveTag">
                                    <span>Save</span>
                                </div>
                            </div>
                            <div class="bottom-done-btn" v-if="!isPopupVisible">
                                <div class="btn-save" @click="hideTagPopup">
                                    <span>Done</span>
                                </div>
                            </div>
                        </ul>
                        <img for="fileInput" class="star-icon" src="../assets/icons/mandatory_start_icon.svg" />
                    </div>
                    <div v-if="showErrorTag && isInvalidTag" class="error-message">Product Tag is required</div>
                </div>
                <ul class="selection-tag">
                    <li v-for="(tag, index) in selectedTags" :key="index" class="select-tag">
                        <span>{{ formattedTag(tag) }}</span>
                        <img src="../assets/icons/cross_icon.svg" @click="removeTag(index)" />
                    </li>
                </ul>
            </div>
            <div class="right-tab">
                <Marketplace />
                <div class="final-card">
                    <div class="section-1">
                        <div class="content-1">
                            <img src="../assets/icons/rating_star_icon.svg" alt="Star" />
                            <span class="data-1">4.5</span>
                        </div>
                        <div class="content-1">
                            <img src="../assets/icons/shopping_cart_icon.svg" alt="Shopping Cart" />
                            <span class="data-1">2.5k</span>
                        </div>
                        <div class="content-1">
                            <img src="../assets/icons/business_contract_icon.svg" alt="Business Contract" />
                            <span class="data-1">1.3k</span>
                        </div>
                        <div class="content-1">
                            <img src="../assets/icons/calendar_schedule_icon.svg" alt="Calendar Schedule" />
                            <span class="data-1">3 Yrs</span>
                        </div>
                    </div>
                    <div class="section-2">
                        <div class="content-2">
                            <div class="label-2">Updated</div>
                            <div class="data-2">23 Aug 2023</div>
                        </div>
                        <div class="content-2">
                            <div class="label-2">Version</div>
                            <div class="data-2">2.3</div>
                        </div>
                        <div class="content-2">
                            <div class="label-2">Size</div>
                            <div class="data-2">30MB</div>
                        </div>
                    </div>
                    <div class="section-3">
                        <div class="label-3">AIDTaaSProduct Artifacts</div>
                        <div class="content-3">
                            <div class="data-r">1.1k</div>
                            <div class="data-3">First level connections</div>
                        </div>
                    </div>
                </div>
                <div class="submit-btns-container">
                    <button class="reset-btn">
                        <img src="../assets/icons/discard_cross_icon.svg" alt="cross" />Discard
                    </button>
                    <button class="create-btn" @click="saveAll">
                        <img src="../assets/icons/check_icon.svg" alt="check" />Save
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Marketplace from "./Marketplacemanagemnet.vue"
import axios from 'axios'

export default {
    components: {
        Marketplace
    },
    data() {
        return {
            activeTab: 'tab1',
            isChecked: false,
            selectedOption: '',
            selectedFile: '',
            fileName: null,
            isImage: false,
            productTags: [],
            tags: [],
            productType: '',
            productName: '',
            productVersion: '',
            showErrorProductType: false,
            showErrorProductName: false,
            showErrorProductVersion: false,
            showErrorTag: false,
            uuid: 'c3d75891-7083-44d6-beb7-e051c7845fd6',
            tabs: false,
            groupConnectedConstructs: false,
            productDefinition: false,
            productData: false,
            imageSizeString: '',
            description: '',
            ownerId: null,
            platformId: null,
            parentPlatformId: null,
            tagPopupVisible: false,
            selectedTags: [],
            cdnUrl: '',
            thumbnail: '',
            showErrorThumbnail: false,
            isInvalidThumbnail: false,
            showErrorMandatoryFields: false,
            fetchedTags: '',
            searchQuery: '',
            isPopupVisible: false,
            isTagPopupVisible: false,
            newTagName: '',
            currentFillColor: '#FFFFFF',
            selectedColor: '#FFFFFF',
            isTagListVisible: true,
            showFlexContainerTag: false,
            allTags: [],
            colorMap: {
                '#FFFFFF': 'white',
                '#FF7B51': 'orange',
                '#EFFF8D': 'yellow',
                '#4DDA36': 'green',
                '#5681D4': 'blue',
                '#DF9CFF': 'purple',
                '#ABABAB': 'grey'
            },
            imagePreviewUrl: ''
        }
    },
    computed: {
        isInvalidProductName() {
            return !this.productName;
        },
        isInvalidProductVersion() {
            // const versionRegex = /^\d+(\.\d+)?$/;
            // return !versionRegex.test(this.productVersion)
            return !this.productVersion;
        },
        isInvalidProductType() {
            return !this.productType;
        },
        isInvalidTag() {
            return this.productTags.length === 0 && this.selectedTags.length === 0;
        },
        filteredTagsComputed() {
            if (!Array.isArray(this.allTags)) {
                console.error("allTags is not an array");
                return [];
            }
            if (!this.searchQuery) {
                return this.allTags;
            }
            const query = this.searchQuery.toLowerCase();
            return this.allTags.filter(tag =>
                tag.tagName.toLowerCase().includes(query) ||
                (this.colorMap[tag.tagColor] && this.colorMap[tag.tagColor].includes(query))
            );
        },
        isTagNotFound() {
            return this.searchQuery && this.filteredTagsComputed.length === 0;
        },
    },
    watch: {
        searchQuery() {
            this.showFlexContainerTag = true;
        }
    },
    created() {
        this.loadTagsFromLocalStorage();
    },
    updated() {
        this.updateLocalStorage();
    },
    methods: {
        triggerFileInput() {
            document.getElementById('fileInput').click();
        },
        removeTag(index) {
            this.selectedTags.splice(index, 1);
        },
        validateProductType() {
            this.showErrorProductType = true;
        },
        validateProductName() {
            this.showErrorProductName = true;
        },
        // validateThumbnail() {
        //     this.showErrorThumbnail = true;
        // },
        validateProductVersion() {
            this.showErrorProductVersion = true;
        },
        validateTag() {
            // If the search term is not empty or there are tags in the list, hide the error message
            this.showErrorTag = this.productTags.length === 0 && this.selectedTags.length === 0;
        },
        toggleCollapse(item) {
            item.collapsed = !item.collapsed;
        },
        toggleTagSelection(tag) {
            if (!tag) return;
            if (this.isSelected(tag)) {
                this.selectedTags = this.selectedTags.filter(selectedTag => selectedTag !== tag);
            } else {
                this.selectedTags.push(tag);
            }
        },
        isSelected(tag) {
            return this.selectedTags.includes(tag);
        },
        hideTagPopup() {
            this.tagPopupVisible = false;
            this.isTagPopupVisible = false;
            this.isPopupVisible = false;
            this.updateLocalStorage();
        },
        hideCreatedTag() {
            this.isTagPopupVisible = false;
            this.isPopupVisible = false;
            this.newTagName = '';
            this.selectedColor = '#FFFFFF';
        },
        updateColor(color) {
            this.currentFillColor = color;
            this.selectedColor = color;
        },
        saveTag() {
            if (this.newTagName.trim() !== '') {
                const newTag = {
                    tagName: this.newTagName,
                    tagColor: this.selectedColor
                };
                // Load existing tags from localStorage
                const storedTags = localStorage.getItem('allTags');
                let allTags = storedTags ? JSON.parse(storedTags) : [];

                // Add the new tag to the list of all tags
                allTags.unshift(newTag);

                // Update the allTags property and localStorage
                this.allTags = allTags;
                localStorage.setItem('allTags', JSON.stringify(allTags));

                // Update filteredTags to display the new tag
                this.filteredTags = this.allTags;

                // Hide the creation popup and reset fields
                this.hideCreatedTag();
            }
        },
        updateLocalStorage() {
            localStorage.setItem('userTags', JSON.stringify(this.allTags));
        },
        loadTagsFromLocalStorage() {
            const storedTags = localStorage.getItem('allTags');
            if (storedTags) {
                this.allTags = JSON.parse(storedTags);
            }
        },
        formattedTag(tag) {
            return tag.tagName.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
                return match.toUpperCase();
            });
        },
        fetchProductTags() {
            console.log("product tags");
            this.tagPopupVisible = true;
            const params = {
                productType: false,
                productTags: true
            };
            const authToken_1 = process.env.VUE_APP_AUTH_TOKEN_1;
            const contentType = process.env.VUE_APP_CONTENT_TYPE;
            const headers = {
                'Authorization': `Bearer ${authToken_1}`,
                'Content-Type': contentType
            };
            const url = new URL('https://ig.mobiusdtaas.ai/hcy-web/v1.0/products/tags-types?');
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

            axios.get(url, { headers: headers })
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error('Network response was not ok');
                    }
                    return response.data;
                })
                .then(data => {
                    this.productTags = data.ProductTags.map((tag, index) => ({
                        tagName: tag,
                        tagColor: this.getColor(index)
                    }));
                    this.allTags = [...this.productTags];
                    this.filteredTags = this.allTags;
                })
                .catch(error => {
                    console.error('Error fetching product tags:', error);
                });
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
                this.imagePreviewUrl = URL.createObjectURL(file);
                this.validateThumbnail(file);
            }
        },
        validateThumbnail(file) {
            if (!file) {
                this.showErrorThumbnail = true;
                this.isInvalidThumbnail = true;
                return;
            }
            this.showErrorThumbnail = false;
            this.isInvalidThumbnail = false;

            console.log("File selected:", file.name);
            this.selectedFile = file;
            const fileType = file.type.split('/')[1].toUpperCase();
            const fileSizeInBytes = file.size;
            const fileSizeInKB = fileSizeInBytes / 1024;
            let sizeString = '';

            if (fileSizeInKB < 1024) {
                sizeString = `${fileSizeInKB.toFixed(2)} KB`;
            } else if (fileSizeInKB < 1024 * 1024) {
                sizeString = `${(fileSizeInKB / 1024).toFixed(2)} MB`;
            } else {
                sizeString = `${(fileSizeInKB / (1024 * 1024)).toFixed(2)} GB`;
            }

            console.log("File size:", sizeString);
            this.imageSizeString = `${fileType} image - ${sizeString}`;
            this.fileName = file.name;
            this.isImage = file.type.includes('image');
        },
        async triggerPostRequest() {
            if (!this.selectedFile) return;

            const file = this.selectedFile;

            const params = {
                filePathAccess: 'private',
                filePath: 'HYC'
            };

            const authToken_2 = process.env.VUE_APP_AUTH_TOKEN_2;

            const formData = new FormData();
            formData.append('file', file);

            Object.keys(params).forEach(key => formData.append(key, params[key]));

            try {
                const response = await axios.post('https://ig.mobiusdtaas.ai/mobius-content-service/v1.0/content/upload', formData, {
                    headers: {
                        'Authorization': `Bearer ${authToken_2}`
                    }
                });

                if (response.status >= 200 && response.status < 300) {
                    const result = response.data;
                    if (result && result.cdnUrl) {
                        this.cdnUrl = result.cdnUrl.startsWith('https') ? result.cdnUrl : `https://cdn.mobiusdtaas.ai${result.cdnUrl}`;
                        console.log('CDN URL:', this.cdnUrl);
                    } else {
                        console.log('CDN URL not found in response');
                    }
                } else {
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        },
        async saveAll() {
            if (!this.productName || !this.productType || !this.productVersion || !this.selectedFile || !this.selectedTags || this.selectedTags.length === 0) {
                this.showErrorMandatoryFields = true;
                this.showErrorProductType = !this.productType;
                this.isInvalidProductType = !this.productType;
                this.showErrorProductName = !this.productName;
                this.isInvalidProductName = !this.productName;
                this.showErrorProductVersion = !this.productVersion;
                this.isInvalidProductVersion = !this.productVersion;
                this.showErrorThumbnail = !this.selectedFile;
                this.isInvalidThumbnail = !this.selectedFile;
                this.showErrorTag = this.selectedTags.length === 0;
                this.isInvalidTag = this.selectedTags.length === 0;
                return;
            }
            await this.triggerPostRequest();

            const ownerId = 'b47ec588-6ef6-4908-b813-1157337259e2';
            const platformId = '667e5f7f6dafc237bd7209ce';
            const productRoles = ['ROLE_CONSUMER', 'ROLE_USER', 'ROLE_MARKETPLACE_USER'];
            try {
                const requestData = {
                    "name": this.productName,
                    "platformId": platformId,
                    "description": "weather service provider",
                    "productType": "XP", // it should be a dropdown
                    "logoUrl": "https://cdn.jim-nielsen.com/ios/512/weather-2021-12-07.png",
                    "productTags": Array.isArray(this.selectedTags) ? this.selectedTags.map(tag => tag.tagName) : [],
                    "servingAreas": [],
                    "snapshotsUrls": [],
                    "relatedInformation": "Dont know what sort of related information.",
                    "attachedDocumentsUrls": [],
                    "videoUrls": [],
                    "videos": [],
                    "website": "www.website.com",
                    "allowsMultiplePurchases": true,
                    "productRoles": productRoles,
                    "config": {},
                    "ownerId": ownerId,
                    "productUrl": "www.google.com/product/url",
                    "version": this.productVersion,
                    "hostedApps": [],
                    "subscriptionStatus": "TRIAL",
                    "domain": "",
                    "allianceInfo": {},
                    "interestedTenants": [],
                    "currency": "USD",
                    "plpConfiguration": {},
                    "participation": "OPEN",
                    "tenantName": "",
                    "alliancedProduct": "",
                    "prices": [
                        "AD_BASED",
                        "Paid"
                    ],
                    "constructType": "BA",
                    "constructId": "123"
                };
                const params = {
                    requiredMasterConfigId: false,
                };
                const authToken_1 = process.env.VUE_APP_AUTH_TOKEN_1;
                const contentType = process.env.VUE_APP_CONTENT_TYPE;
                const headers = {
                    'Authorization': `Bearer ${authToken_1}`,
                    'Content-Type': contentType
                };
                const url = new URL('https://ig.aidtaas.com/hcy-web/v1.0/products');
                Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

                const response = await axios.post(
                    url.toString(),
                    requestData,
                    { headers }
                );
                console.log("response data", response)
                if (response.status === 201) {
                    console.log('Product created successfully:', response.data);
                } else {
                    console.error('Unexpected status code:', response.status);
                }
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    console.error('Internal server error:', error.message || error);
                } else if (error.response && error.response.status === 400) {
                    console.error('Bad request:', error.response.data);
                } else {
                    console.error('Error making request:', error.message || error);
                }
            }
        },
        getColor(index) {
            const colors = ['#FFFFFF', '#FF7B51', '#EFFF8D', '#4DDA36', '#5681D4', '#DF9CFF', '#ABABAB'];
            return colors[index % colors.length];
        },
        createNewTag() {
            this.isTagPopupVisible = true;
        },
        capitalizeFirstLetter(tag) {
            if (!tag || typeof tag !== 'string') return '';
            return tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase();
        },
        addNewTag() {
            this.isPopupVisible = true;
            this.isTagPopupVisible = true;
        },
        customisedTag() {
            this.isPopupVisible = true;
            this.isTagPopupVisible = true;
            this.isTagListVisible = true;
            this.showFlexContainerTag = false;
            this.newTagName = this.searchQuery;
            this.searchQuery = ''
        }
    }
}
</script>

<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 5px;
}

.main-container {
    position: relative;
}

.right-drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 650px;
    height: 100%;
    border: 1px solid #eaeaea;
    background: #fff;
    /* box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2); */
    transition: transform 0.3s ease;
    overflow: hidden;
    z-index: 1000;
}

.right-drawer button,
.right-drawer label {
    cursor: pointer;
}

.right-drawer.closed {
    width: 0;
}

.form-header {
    flex: 1;
    border: 2px solid #EAEAEA;
    display: flex;
    justify-content: space-between;
    /* max-width: calc(100% - 10vh); */
}

.marketpalce-header {
    padding: 10px;
}

.img-right {
    padding: 10px;
    border: 0.8px solid #F9F9F9;
}

.marketplace-management {
    color: #2C2C2C;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.tabs {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.product-tab {
    padding: 5px;
    cursor: pointer;
    border: none;
    outline: none;
    width: 48%;
    background-color: white;
    margin: 0 10px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Plus Jakarta Sans', sans-serif;
    line-height: 20px;
    border-bottom: 1px solid #2c2c2c
}

.marketplace-tab {
    opacity: 0.45;
    padding: 5px;
    cursor: pointer;
    border: none;
    outline: none;
    width: 48%;
    background-color: white;
    margin: 0 10px;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #2c2c2c
}

.tab.active {
    border-bottom-color: #EAEAEA;
    ;
}

.container {
    display: flex;
    height: 100%;
    width: 100%;
}

.left-tab {
    margin: 10px;
    display: inline-flex;
    height: 88vh;
    width: 46%;
    padding: 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1;
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.uuid {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.uuIdHead {
    color: #767676;
    margin-right: 10px;
    font-family: 'Plus Jakarta Sans', sans-serif;

}

.refresh-btn {
    color: #2c2c2c;
    font-family: Plus Jakarta Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    opacity: .7;
}

.input-uuid {
    font-size: 12px;
    font-weight: 400px;
    margin-top: 10px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    width: 45vh;
    border: none;
    outline: none;
    color: #2c2c2c;
    font-style: normal;
    line-height: 20px;
}

.publish-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 260px;
}

.heading-wrapper {
    color: #767676;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.checkboxes {
    width: 100%;
}

.additional-info {
    padding-left: 25px;
    display: flex;
    font-weight: 400;
    color: #000000;
    font-size: 12px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    justify-content: space-between;
}

.select-text {
    color: #0376BF;
}

input[type="checkbox"] {
    display: none;

}

.checkbox-label {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding-left: 25px;
    color: #000000;
    position: relative;
    cursor: pointer;
    font-style: normal;
    line-height: normal;

}

.checkbox-label:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 1px solid #0376BF;
    border-radius: 3px;
}

.checkbox-label:after {
    /* Unicode character for checkmark */
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 12px;
    display: none;
}

.checkbox-label input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

input[type="checkbox"]:checked+.checkbox-label:before {
    content: '\2713';
    font-size: 14px;
    line-height: 13px;
    text-align: center;
    background-color: #0376BF;
    color: #F9F9F9;
}

.version-head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 260px;
}

.radios {
    width: 100%;
}

.custom-radio {
    /* Hide the default radio button */
    /* appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; */
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid #0376BF;
    cursor: pointer;
    vertical-align: middle;
}

.custom-radio:checked {
    accent-color: #0376BF;
}

.radio-label {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: normal;
    padding-left: 8px;
    display: inline-block;
    vertical-align: middle;
}

.thumb {
    width: 100%;
    position: relative;
}

.thumbnail {
    color: #767676;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: block;
    line-height: 16px;
    margin-bottom: 5px;
}

.thumbnail-container {
    display: flex;
    flex-direction: row;
    width: 94%;
    height: 112px;
    position: relative;
}

.thumb-star-icon {
    position: absolute;
    top: 5px;
    left: 2px;
    width: 8px;
    height: 8px;
}

.image {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    background-color: #f9f9f9;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    /* clip-path: polygon(0 30%, 3% 0, 15200% 100%, 0 100%); */
    clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0 100%, 0 25%);
}

.upload-label {
    color: #2c2c2c;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    cursor: pointer;
}

.selected-img {
    display: none;
    position: relative;
}

.selected-img+img {
    width: 80px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 15px;
    margin-top: 5px;
    border-radius: 10px;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 94%;
}

.star-icon {
    position: absolute;
    top: 5px;
    left: 1px;
    width: 8px;
    height: 8px;

}

.imgFile input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.imgFile .image img {
    position: absolute;
    top: 0;
    left: 16px;
    width: 25%;
    height: 75%;
    /* object-fit: cover; */
    z-index: 0;
}

.imgFile img {
    width: 10%;
    height: 70%;
}

.fileName {
    color: #000000;
    font-family: SF Pro Display;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    margin-left: 15px;
    position: absolute;
    left: 0;
}

.image .fileName {
    margin-top: 50px;
}

.thumbnail-container.flex {
    flex-direction: column;
}

.input-box {
    width: 100%;
    position: relative
}

.input-title {
    color: #767676;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: block;
    line-height: 16px;
    margin-bottom: 6px;
}

.input-con {
    position: relative;
    height: 32px;
    width: 97%;
    left: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    transform: skew(-14deg);
    background-color: #f9f9f9;
}

.input-title-label {
    color: #2f3238;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 12px;
    margin-left: 5px;
    border-top-left-radius: 4px;
    transform: skew(14deg);
    width: 100%;
    height: 100%;
    border: none;
    background-color: #f9f9f9;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;

}

.input-title-label:focus {
    outline: none;
}

.selection-tag {
    width: 94%;
    height: 70%;
}

.select-tag {
    border-radius: 10px;
    margin: 3px;
    padding: 2px 2px 2px 8px;
    background-color: #51BB10;
    color: #fff;
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.12px;
    letter-spacing: 0.3px;
    gap: 3px;
}

.select-tag img {
    cursor: pointer;
}

.error-message {
    color: #ee322c;
    text-align: right;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 10px;
    line-height: 12px;
    width: 100%;
}

.text-area {
    background-color: #f9f9f9;
    width: 100%;
    height: 80px;
    color: #2f3238;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    padding-left: 12px;
    border: none;
    outline: none;
    overflow: auto;
    border-radius: 5px;
    clip-path: polygon(0 30%, 5% 0, 15200% 100%, 0 100%);
}

.right-tab {
    margin: 10px 13px;
    display: inline-flex;
    height: 90vh;
    width: 46%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

li {
    cursor: pointer;
}

.right-side-bar {
    position: absolute;
    top: 0;
    left: 0;
}

.fileSize {
    color: #9C9C9C;
    font-family: SF Pro Display;
    font-size: 10px;
    font-weight: 400;
    position: absolute;
    left: 0;
    margin-left: 15px;
    line-height: 11.93px;
}

.image .fileSize {
    margin-top: 90px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.right-border {
    border: 2px solid #0387d3;
    position: absolute;
    right: 3px;
    height: 83%;
    border-radius: 5px;
}

.right-border-thumbnail {
    border: 2px solid #0387d3;
    position: absolute;
    right: 3px;
    top: 0.3em;
    height: 92%;
    border-radius: 5px;
}

.right-border-descp {
    border: 2px solid #0387d3;
    position: absolute;
    right: 21px;
    top: 1.6em;
    height: 72%;
    border-radius: 5px;
}

.submit-btns-container {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 46%;
    height: 32px;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    box-shadow: 0 -2px 8px #00000014;
}

.submit-btns-container .reset-btn {
    width: 40%;
}

.submit-btns-container button {
    border: none;
    padding: 0.5em 1em;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.submit-btns-container .reset-btn img {
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 5px;
}

.submit-btns-container .create-btn {
    width: 60%;
    background-color: #00a1ed;
    color: #fff;
}

.submit-btns-container .create-btn img {
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 5px;
}

.product-tag-list {
    position: absolute;
    background-color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 1000;
    width: 100%;
    min-width: 16rem;
    max-height: auto;
    /* overflow-y: auto;
    overflow-x: hidden; */
    font-size: 12px;
    font-weight: 400;
    font-family: 'Plus Jakarta Sans', sans-serif;
    right: 0 !important;
    left: auto !important;
    box-shadow: 0px 2.64px 2.64px 0px #00000040;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(0px, 34px, 0px);
}

.product-tag-list li {
    cursor: pointer;
    padding: 5px 0;
}

.product-tag-list li:hover {
    background-color: #f0f0f0;
}

.tag-popup {
    padding: 10px 0;
}

.search-bar {
    margin-top: .5rem;
    border-bottom: 1.5px solid #C4C4C4;
    display: flex;
    column-gap: 5px;
    align-items: center;
    margin-bottom: .21rem;
}

.search-tag {
    outline: none;
    border: none;
    opacity: 50%;
    width: 85%;
    height: 2rem;
    font-size: 11.88px;
    font-weight: 300;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.create-tag-input {
    display: flex;
    align-items: center;
    background-color: #deeff3;
    justify-content: space-evenly;
    padding-bottom: .25rem;
    column-gap: 4px;
}

.color-filling,
.delete-btn {
    cursor: pointer;
    vertical-align: middle;
}

.create-tag-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
    border-bottom: 1px solid rgba(0, 0, 0, .421);
    /* margin-bottom: .21rem; */
}

.create-input {
    width: 85%;
    height: 2rem;
    border: none;
    outline: none;
    color: #0000005d;
    background-color: #deeff3;
}

.create-tag-color {
    padding-left: 2.5rem;
    padding-bottom: .5rem;
    padding-top: .3rem;
    display: flex;
    align-items: center;
    gap: .8rem;
    background-color: #deeff3;
}

.manage-tags {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    font-size: 15.84px;
    color: #000000;
}

.creation-tag {
    display: none;
}

.creation-tag.active {
    display: initial;
}

.tag-list {
    /* margin-top: .5rem; */
    margin-bottom: .5rem;
    height: 14.5rem;
    overflow-y: scroll;
    /* border: 1px solid; */
    list-style-type: none;
}

.items {
    width: 100%;
    height: fit-content;
    font-size: 14px;
    color: #000000;
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 0.5vw;
    border-radius: 0.5vw;
}

.items:hover {
    background-color: #deeff3 !important;
    cursor: pointer;
}

.circle {
    /* background-color: rgb(255, 123, 81); */
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s;
}

.active-circle {
    transform: scale(1.5);
}

.flex-container-tag {
    display: flex;
    align-items: center;
}

.flex-container-tag:hover {
    padding: 5px 0;
    background-color: #deeff3;
}

.newTag-circle {
    width: 12px;
    height: 12px;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 5px;
    /* Adjust margin as needed */
}


.item-content {
    display: flex;
    align-items: center;
}

.item-content input {
    margin-right: 0.5vw;
}

.bottom-save-btn {
    width: 100%;
    display: flex;
    max-height: 38px;
    border-radius: 6px;
    padding-bottom: 1rem;
}

.btn-discard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: .5rem;
    width: 48%;
    min-height: 30px;
    text-align: center;
    cursor: pointer;
}

.btn-discard:hover {
    background-color: #deeff3;
}

.btn-discard span {
    font-family: 'Plus Jakarta Sans', sans-serif;
    ;
    font-size: 10.56px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -.011em;
    text-align: center;
    cursor: pointer;
    margin: 0;
}

.bottom-done-btn {
    width: 100%;
    display: flex;
    max-height: 38px;
    /* padding-bottom: 1rem; */
    justify-content: flex-end;
    cursor: pointer;
}

.btn-save {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #006EDB;
    border-radius: .5rem;
    width: 48%;
    min-height: 30px;
    text-align: center;
    cursor: pointer;
}

.btn-save:hover {
    background-color: #deeff3;
}

.btn-save span {
    color: #2C3948;
    font-weight: 500;
    font-size: 10.56px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.create-tag {
    display: block;
    font-size: 11.88px;
    font-weight: 400;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #000000;
    opacity: 50%;
    cursor: pointer;
}

.final-card {
    border-top: 2px solid #EAEAEA;
    position: absolute;
    bottom: 48px;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.section-1 {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
}

.content-1 {
    padding: 0;
    display: flex;
}

.content-1 img {
    vertical-align: middle;
}

.data-1 {
    color: var(--bg-text, #2C2C2C);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.section-2 {
    display: flex;
    width: 281px;
    justify-content: space-between;
    align-items: flex-start;
}

.content-2 {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.label-2 {
    color: var(--bg-text, #2C2C2C);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.data-2 {
    color: var(--bg-text, #2C2C2C);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
}

.section-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.label-3 {
    color: var(--bg-text, #2C2C2C);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.content-3 {
    gap: 8px;
    padding: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-r {
    color: #000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
}

.data-3 {
    color: #2C2C2C;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 93.75%
}
</style>